import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../modal/quickbase-modal"

import "./hero.css"

const HeroQuickbase = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(relativePath: { eq: "new-hero-Background.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-home-quickbase"
        // preserveStackingContext
        style={{ backgroundPosition: "top center", height: "600px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <h3 className="page-title font-weight-medium mt-0 mb-4">
                  Integrations Support with Quandary
                </h3>
                <p className="mb-0">
                  Looking for help with Quickbase integrations? As your
                  Quickbase consultant, we'll take the headache out of
                  connecting your application stack. Get more out of Pipelines
                  with Quandary.
                </p>
                <p className="h5 text-left pt-3 font-italic font-weight-bold mb-4">
                  Learn how we can help you
                </p>

                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroQuickbase
