import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout-solution"
import Seo from "../components/seo"

//components
import Cases from "../components/cases"
import Modal from "../components/modal/quickbase-modal"
import PartnerSwiper from "../components/swiper/partners.js"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-quickbase.js"

class QuickbaseIntegrationSolutionPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo title={siteTitle} description={siteDescription} />
        <div id="main">
          <LazyLoadComponent>
            <section
              id="quandary-help-wrapper"
              className="quandary-help-wrapper mt-5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="font-weight-medium">
                      ORGANIZATIONS <span className="text-green">TRUST</span>{" "}
                      QUANDARY CONSULTING GROUP
                    </h2>
                  </div>
                </div>
                <LazyLoadComponent>
                  <PartnerSwiper />
                </LazyLoadComponent>
              </div>
            </section>
            {/* </LazyLoad> */}
            {/* <LazyLoad height={200} once> */}
            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>QUICKBASE INTEGRATIONS SUPPORT</h1>
                    <p>
                      As your top-rated, Partner, we'll work with your team to
                      squeeze more value out of Quickbase Integrations: Connect
                      your apps. Seamlessly move data between systems. Get
                      Real-Time Insights from Clear Data Dashboards.
                    </p>
                    <div className="pt-5">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="quickbase-integrations">
              <div className="container quickbase-integrations-padding">
                <div className="row">
                  <div className="col-lg-12 text-center pt-5">
                    <h3 className="text-white mt-4">
                      STRUGGLING WITH QUICKBASE INTEGRATIONS? QUANDARY OFFERS
                      THE SUPPORT YOU NEED.
                    </h3>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/coding-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      NO MORE TROUBLESHOOTING
                    </p>
                    <p className="pt-2 text-white">
                      Tell us the integrations you need and let us handle the
                      rest.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/Drag-Icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      CLEAR DASHBOARDING
                    </p>
                    <p className="pt-2 text-white">
                      We set up data dashboards to provide you with clear,
                      actionable insights.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/dashboard-icons.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      GET MORE OUT OF PIPELINES
                    </p>
                    <p className="pt-2 text-white">
                      We help you squeeze every drop of value out of Quickbase,
                      giving you more. Much more.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/phone-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      LONG-TERM PARTNERS
                    </p>
                    <p className="pt-2 text-white">
                      As your technology consultants, we'll constantly seek out
                      new ways to make your business more competitive.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/automate-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      UNPARALLELED RESPONSIVENESS
                    </p>
                    <p className="pt-2 text-white">
                      Questions? Concerns? Frustrations? We'll never keep you
                      waiting.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/roi-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ACHIEVE A STRONGER ROI
                    </p>
                    <p className="pt-2 text-white">
                      We'll show you how you can get a much higher return on
                      your investment in Quickbase.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      WHY CHOOSE QUANDARY CONSULTING GROUP?
                    </h2>
                    <p className="mt-3">
                      Quickbase Integrations bridge the gaps between your
                      systems. And we build those bridges for you, <br />{" "}
                      empowering your business to scale with ease.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      HIGHER ROI
                    </p>
                    <p className="my-3 pb-3">
                      We'll help you get more out of <br /> Quickbase Pipelines
                      while finding
                      <br />
                      more opportunities to increase your overall ROI.
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      MORE TIME
                    </p>
                    <p className="my-3 pb-3">
                      Outsource your integration <br /> development to us and
                      give <br /> team more time to <br />
                      focus on more strategic tasks.
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      INSTANT RELIEF
                    </p>
                    <p className="my-3 pb-3">
                      Wave "Goodbye" to your integration <br /> struggles and
                      frustrations <br />
                      once we take the reigns.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>
            {/* </LazyLoad> */}
            {/* <LazyLoad height={200} once> */}
            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container-fluid px-5">
                <div className="row">
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">CONTINUES TO IMPROVE UIS</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "After an exhaustive search to find the right Quickbase
                      Partner to enhance and upgrade our existing App, we came
                      across the team at Quandary. Within the first few minutes
                      of our discovery call, Kevin and his team completely
                      understood our needs, developed a strategy for success,
                      and delivered on-time, within budget and provided above
                      and beyond service, with add-on's that we didn't know were
                      possible after speaking with other providers. I cannot
                      recommend the team at Quandary enough. Their in-depth
                      understanding of our business process, their patience with
                      our limited understanding of technology, and ultimately
                      their implementation of a fantastic product --
                      communicating every step of the way -- has been a 'win'
                      for our business. We are looking forward to years of
                      partnership with this caring, competent and communicative
                      team from Quandary."
                    </p>
                    <div className="py-3">
                      <p>
                        - Derek Hutchinson - Quality Engineer
                        <br />
                        PSG Dover
                      </p>
                    </div>
                  </div>

                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      WE USED QUICKBASE IN OUR ENTIRE ORGANIZATION
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "I have been working with Quandary Consulting Group and
                      Quickbase for almost 2 years. Quickbase provided a way to
                      streamline a unique billing/payable process. Quandary
                      designed a program specifically to transfer information
                      between multiple systems. Tracking and reconciling
                      information is efficient and simple. The staff reacts
                      quickly to the changing processes by providing timely
                      updates and superior support."
                    </p>
                    <div className="py-3">
                      <p>
                        - Jane Baum - Accounting Manager
                        <br />
                        Jacobs
                      </p>
                    </div>
                  </div>
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      ITS BEEN A WONDERFUL ADDITION
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "My team has been with several partners over the years and
                      Quandary stands out above the rest. They really understand
                      our business and focus our Quickbase initiatives and
                      applications truly on what we need. Their technical
                      abilities are outstanding and can accomplish any request
                      we throw at them whether through Quickbase or utilizing
                      integration tools. They are communicative, professional,
                      and total experts. As part of our team, they even help us
                      manage and put on our annual conference. We couldn't do it
                      without them. Thank you for everything you do for us
                      Quandary!"
                    </p>
                    <div className="py-3">
                      <p>
                        - Nick Couture - Marketing Coordinator
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                        {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                  <div className="text-center">
                    <Link
                      className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                      to="/case-studies/"
                    >
                      More Case Studies
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section id="case-studies-container">
              <div id="case-studies">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="my-2 font-weight-medium">
                        READY TO GET MORE OUT OF{" "}
                        <span className="text-green">
                          QUICKBASE PIPELINES FOR YOUR BUSINESS?
                        </span>
                      </h2>
                      <p>
                        Struggling to get the most out of Quickbase
                        integrations? Your frustration ends now.
                        <br /> We'll help you get the most out of Pipelines for
                        your business.
                      </p>
                      <div className="col-lg-5 mx-auto text-center pt-3 ">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default QuickbaseIntegrationSolutionPage

export const QuickbaseIntegrationSolutionPageQuery = graphql`
  query quickbaseIntegrationSolutionPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
